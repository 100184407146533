import React, { useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

const Container = styled.div`
  width: 100%;
  max-height: 100%;
  aspect-ratio: 16/9;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: var(--m);
`;

const Loader = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: var(--secondary);
  position: absolute;
  border-radius: var(--m);
  overflow: hidden;

  &:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to right,
      transparent 0%,
      #eaeaea 50%,
      transparent 100%
    );
    animation: load 1.5s ease-in-out infinite;

    [data-theme="dark"] & {
      background: linear-gradient(
        to right,
        transparent 0%,
        #353535 50%,
        transparent 100%
      );
    }

    @keyframes load {
      from {
        left: -100%;
      }
      to {
        left: 100%;
      }
    }
  }
`;

interface ImageLoaderProps {
  src: string;
  alt: string;
}

const ImageLoader = ({ src, alt }: ImageLoaderProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Container>
      <AnimatePresence>
        {!isLoaded && <Loader animate={{ opacity: 1 }} exit={{ opacity: 0 }} />}
      </AnimatePresence>
      <Image
        loading="lazy"
        src={src}
        alt={alt}
        onLoad={() => setIsLoaded(true)}
        onLoadStart={() => setIsLoaded(false)}
      />
    </Container>
  );
};

export default ImageLoader;
