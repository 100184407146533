import React from "react";
import styled from "styled-components";

import Event, { VatsimEvent } from "./Event";
import { humanReadableDay } from "./helpers";

const Container = styled.div`
  & > a > section {
    margin-bottom: var(--l);
  }

  & > a > &:last-child {
    margin-bottom: var(--s);
  }
`;

const Title = styled.p`
  font-size: 1.25rem;
  font-weight: var(--bold);
  margin: 0 calc(var(--ml) * -1);
  padding: var(--l) var(--ml);
  position: sticky;
  top: -1px;
  z-index: 2;
  background-color: var(--secondary);
`;

export interface EventCollection {
  date: string;
  events: VatsimEvent[];
}

interface CollectionProps {
  collection: EventCollection;
}

const Collection = ({ collection }: CollectionProps) => {
  return (
    <Container>
      <Title>{humanReadableDay(collection.date)}</Title>
      {collection.events.map((event, i) => (
        <Event key={i} event={event} />
      ))}
    </Container>
  );
};

export default Collection;
