import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { RouteComponentProps } from "@reach/router";

import { isSSR } from "../../hooks/helpers";
import { DOCS_HEADER_HEIGHT } from "../Header/Header";
import Header from "../Header";
import { useVatsimEvents } from "../../context/VatsimEvents";
import { M_BREAKPOINT } from "../../styles";

import { EventCollection } from "./Collection";
import Map from "./Map";
import EventsList from "./EventsList";

const Main = styled.main`
  display: flex;
  height: calc(100vh - ${DOCS_HEADER_HEIGHT});
  margin-top: ${DOCS_HEADER_HEIGHT};

  @media (max-width: ${M_BREAKPOINT}px) {
    // hide EventsList on mobile (place in Header)
    & > aside {
      display: none;
    }
  }
`;

const Loader = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;

  [data-theme="dark"] & {
    background-color: rgba(0, 0, 0, 0.95);
  }
`;

const LoaderModal = styled.div`
  background-color: var(--secondary);
  padding: var(--xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--m);

  &:after {
    position: absolute;
    padding: var(--l);
    font-weight: var(--bold);
    content: "Loading Events...";
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const Spinner = styled.div`
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background: linear-gradient(var(--vatsimGreen), var(--vatsimBlue));
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotate} 1s infinite linear;

  &:before {
    content: "";
    position: absolute;
    background-color: var(--secondary);
    height: 97%;
    width: 97%;
    border-radius: 50%;
  }
`;

const MobileHeaderContainer = styled.div`
  max-width: 100%;
  display: none;
  margin-top: var(--l);

  & > aside {
    position: relative;
    width: 100%;
  }

  @media (max-width: ${M_BREAKPOINT}px) {
    display: block;
  }
`;

const EventsPage = (_: RouteComponentProps) => {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const data = useVatsimEvents();
  const [events, setEvents] = useState<EventCollection[]>();

  useEffect(() => {
    if (!data) {
      return;
    }
    const datedEvents = data?.slice(0, 50).reduce((ctx, event) => {
      const date = new Date(event.startTime).toDateString();

      const dateIsGrouped = ctx.findIndex((group) => group.date === date);

      dateIsGrouped === -1
        ? ctx.push({ date, events: [event] })
        : ctx[dateIsGrouped].events.push(event);

      return ctx;
    }, [] as EventCollection[]);

    setEvents(datedEvents);
  }, [data]);

  return (
    <>
      {!events && (
        <Loader>
          <LoaderModal>
            <Spinner />
          </LoaderModal>
        </Loader>
      )}
      <Header
        open={navIsOpen}
        setOpen={setNavIsOpen}
        headerContent={
          <MobileHeaderContainer>
            <EventsList events={events} />
          </MobileHeaderContainer>
        }
      />
      <Main>
        <EventsList events={events} />
        {!isSSR && <Map events={events} />}
      </Main>
    </>
  );
};

export default EventsPage;
