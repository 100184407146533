import React from "react";
import { Router } from "@reach/router";
import styled from "styled-components";

import EventsPage, { EventPage } from "../components/Events";
import { isSSR } from "../hooks/helpers";

const Container = styled.div`
  min-height: 100vh;
  overflow: hidden;
  background-color: var(--primary);
`;

const Events = () => {
  if (isSSR) {
    return null;
  }

  return (
    <Container>
      <Router basepath="/events">
        <EventsPage path="/" />
        <EventPage path="/:name" />
      </Router>
    </Container>
  );
};

export default Events;
