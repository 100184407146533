import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { RouteComponentProps, useParams } from "@reach/router";
import { ArrowLeft } from "react-feather";
import { Link } from "gatsby";

import { useVatsimEvents } from "../../../context/VatsimEvents";
import Header from "../../Header";
import { humanReadableDay, humanReadableStartEnd } from "../helpers";
import { VatsimEvent } from "../Event";
import { S_BREAKPOINT } from "../../../styles";
import Pill from "../Pill";
import { DOCS_HEADER_HEIGHT } from "../../Header/Header";

import ImageLoader from "./ImageLoader";
import SideBar from "./SideBar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;

const Content = styled.div`
  max-width: min(1400px, 100vw);
  display: flex;
  margin-bottom: 5rem;
  padding: var(--xl) var(--gutter);
  margin-top: ${DOCS_HEADER_HEIGHT};

  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

const Title = styled.h1`
  font-size: 2.75rem;
  font-weight: var(--xBold);
  line-height: 3rem;

  @media (max-width: ${S_BREAKPOINT}px) {
    font-size: 1.75rem;
  }
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const SectionDescription = styled.h4`
  font-weight: var(--xBold);
  letter-spacing: 0.1em;
  margin-bottom: var(--xl);
  text-transform: uppercase;
`;

const ShowcaseContainer = styled.div`
  max-width: 1400px;
  padding: var(--xxl) var(--gutter);
  width: 100%;
  margin-top: var(--xl);
`;

const Showcase = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: var(--l);
  justify-content: center;
  max-width: 100%;
`;

const ShowcaseItem = styled.li`
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  transition: box-shadow 200ms ease-in-out;

  &:hover {
    box-shadow: var(--shadowXL);
  }
`;

const BackButton = styled.div`
  margin-bottom: var(--l);
  align-self: flex-start;

  & > a {
    color: inherit;
    display: flex;
    align-items: center;
    font-weight: var(--bold);

    &:hover {
      color: var(--accent);
    }

    & > svg {
      margin-right: var(--ml);
    }
  }
`;

const Info = styled.div`
  margin: var(--l) 0;
  font-weight: var(--bold);
  display: flex;
  align-items: center;

  & > span {
    margin-right: var(--ml);
  }
`;

interface EventPageProps
  extends RouteComponentProps<{ location: { state: { event: VatsimEvent } } }> {
  event?: VatsimEvent;
}

// const twitterURL = "https://twitter.com/intent/tweet?hashtags=VATSIM";
// href={`${twitterURL}&text=I'm flying out of the ${event.name} event on`}

const EventPage = ({ location }: EventPageProps) => {
  const [event, setEvent] = useState<VatsimEvent>();
  const { name }: { name: string } = useParams();
  const events = useVatsimEvents();
  const [navIsOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    if (location?.state?.event) {
      return setEvent(location.state.event);
    }

    if (name) {
      const eventName = decodeURI(name);
      const result = events?.find((e) => e.name === eventName);

      if (result) {
        setEvent(result);
      }
    }
  }, [events, name]);

  const upcoming = useMemo(() => {
    const nonDuplicate = events
      ?.slice(0, 10)
      .filter((e) => e.name !== event?.name);
    return nonDuplicate?.slice(0, 3);
  }, [events, event]);

  const isVasoEvent = useMemo(() => event?.type === "VASOPS Event", [event]);

  return (
    <Container>
      <Header open={navIsOpen} setOpen={() => setIsNavOpen(!navIsOpen)} />
      {event && (
        <>
          <Content>
            <Main>
              <BackButton>
                <Link to="/events">
                  <ArrowLeft /> Events
                </Link>
              </BackButton>
              <Title>{event.name}</Title>
              <Info>
                <span>
                  {humanReadableDay(event?.startTime)} @ {humanReadableStartEnd(event)}
                </span>
                {isVasoEvent && (
                  <div>
                    <Pill label="VA / VSO" />
                  </div>
                )}
                {event?.airports?.map((airport, i) => (
                  <div key={i}>{airport && <Pill label={airport?.icao} />}</div>
                ))}
              </Info>
              <BannerContainer>
                <ImageLoader src={event.bannerLink} alt="event banner" />
              </BannerContainer>
            </Main>
            <SideBar event={event} />
          </Content>
          <ShowcaseContainer>
            <SectionDescription>Upcoming Events</SectionDescription>
            <Showcase>
              {upcoming?.slice(0, 3).map((e, i) => (
                <Link to={`/events/${encodeURI(e.name)}`} state={{ e }} key={i}>
                  <ShowcaseItem>
                    <ImageLoader
                      src={e.bannerLink}
                      alt="upcoming event banner"
                    />
                  </ShowcaseItem>
                </Link>
              ))}
            </Showcase>
          </ShowcaseContainer>
        </>
      )}
    </Container>
  );
};

export default EventPage;
