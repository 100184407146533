import React from "react";
import styled from "styled-components";

const Container = styled.div<{ color: string }>`
  padding: var(--m);
  font-weight: var(--bold);
  margin-right: var(--m);
  font-size: 12px;
  border-radius: 5rem;
  background-color: rgba(61, 79, 224, 0.2);
  border: 1px solid var(--accent);

  [data-theme="light"] & {
    color: var(--accent);
  }
`;

interface PillProps {
  label: string;
}

const Pill = ({ label }: PillProps) => {
  return <Container color="aqua">{label}</Container>;
};

export default Pill;
