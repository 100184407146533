import htmr from "htmr";
import { HtmrOptions } from "htmr/src/types";
import React from "react";
import styled from "styled-components";

import { VatsimEvent } from "../Event";

import {
  H3,
  Paragraph,
  Divider,
  OrderedList,
  UnOrderedList,
  BlockQuote,
  A,
  U,
} from "./Elements";

const Aside = styled.aside`
  flex: 1;
  margin-left: var(--xl);
  margin-top: 2rem;

  @media (max-width: 800px) {
    margin-left: 0;
    margin-top: var(--l);
  }
`;

const SectionDescription = styled.h4`
  font-weight: var(--xBold);
  letter-spacing: 0.1em;
  margin-bottom: var(--m);
  text-transform: uppercase;
`;

const transform = {
  h3: H3,
  p: Paragraph,
  hr: Divider,
  ol: OrderedList,
  ul: UnOrderedList,
  blockquote: BlockQuote,
  a: A,
  u: U,
} as HtmrOptions["transform"];

interface SideBarProps {
  event: VatsimEvent;
}

const SideBar = ({ event }: SideBarProps) => {
  return (
    <Aside>
      <SectionDescription>Description</SectionDescription>
      {htmr(event.description, { transform })}
    </Aside>
  );
};

export default SideBar;
