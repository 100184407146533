import React, { useMemo } from "react";
import { Info } from "react-feather";
import styled from "styled-components";
import { Link } from "gatsby";

import { isInProgress } from "./helpers";
import Pill from "./Pill";

const Container = styled.section<{ isLive: boolean }>`
  padding: var(--l);
  border-radius: var(--m);
  margin-top: var(--s);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.075);
  background-color: var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  color: var(--textColor);

  [data-theme="dark"] & {
    background-color: #313131;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.15);
  }

  ${({ isLive }) =>
    isLive &&
    `
      margin: 2px;

      ::before {
        z-index: -1;
        position: absolute;
        content: "";
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background: linear-gradient(90deg, var(--vatsimBlue), var(--vatsimGreen));
        border-radius: var(--m);
      }
  `}
`;

const Title = styled.p<{ isLive: boolean }>`
  font-weight: var(--bold);
  font-size: 1.075rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: normal;
  align-self: flex-start;
  width: ${({ isLive }) => (isLive ? "calc(100% - 1.5rem)" : "100%")};
`;

const Details = styled.div`
  display: flex;
  margin-top: var(--m);
  width: 100%;
`;

const Label = styled.p`
  font-size: var(--sText);
  font-weight: var(--bold);
  margin-right: var(--m);
  width: max-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const PillContainer = styled.div`
  display: flex;
  overflow-x: auto;
`;

const LiveContainer = styled.div`
  position: absolute;
  right: 0;
  top: var(--m);
  overflow: hidden;
`;

const LiveBanner = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--m);
  background: linear-gradient(-90deg, var(--vatsimBlue), var(--vatsimGreen));
  letter-spacing: 0.15em;
  font-weight: var(--xBold);
  font-size: var(--sText);
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(3, 27, 78, 0.15);
  border-top-left-radius: var(--m);
  border-bottom-left-radius: var(--m);
  transform: translateX(calc(100% - 2rem));
  transition: transform 100ms ease-in-out;
  color: white;

  &:hover {
    transform: translateX(0);
  }

  & > :first-child {
    width: 1rem;
    height: 1rem;
    margin-right: var(--m);
  }
`;

export interface Airport {
  icao: string;
  name?: string;
  city?: string;
  country?: string;
  coords?: [number, number];
}

export interface VatsimEvent {
  airports: Airport[];
  type: string;
  name: string;
  link: string;
  startTime: string;
  endTime: string;
  shortDescription: string;
  description: string;
  bannerLink: string;
}

interface EventProps {
  event: VatsimEvent;
}

const Event = ({ event }: EventProps) => {
  const isLive = useMemo(() => isInProgress(event.startTime, event.endTime), [
    event,
  ]);

  const isVasoEvent = useMemo(() => event.type === "VASOPS Event", [event]);

  const url = useMemo(() => {
    const name = event.name.replaceAll("/", "_");
    return encodeURI(name);
  }, [event]);

  return (
    <Link to={`/events/${url}`} state={{ event }}>
      <Container isLive={isLive} role="button" aria-label="Open event details">
        {isLive && (
          <LiveContainer>
            <LiveBanner>
              <Info fontSize={14} />
              <span>In Progress</span>
            </LiveBanner>
          </LiveContainer>
        )}
        <Title isLive={isLive}>{event.name}</Title>
        <Details>
          <Label>
            {new Intl.DateTimeFormat("en-GB", {
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(event.startTime))}{" "}
            -{" "}
            {new Intl.DateTimeFormat("en-GB", {
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(event.endTime))}
          </Label>
          <PillContainer>
            {isVasoEvent && (
              <div>
                <Pill label="VA / VSO" />
              </div>
            )}
            {event?.airports?.map((airport, i) => (
              <div key={i}>{airport && <Pill label={airport?.icao} />}</div>
            ))}
          </PillContainer>
        </Details>
      </Container>
    </Link>
  );
};

export default Event;
