import React from "react";
import styled from "styled-components";

import { DOCS_HEADER_HEIGHT } from "../Header/Header";

import Collection, { EventCollection } from "./Collection";

const Container = styled.aside`
  position: absolute;
  width: auto;
  overflow-x: hidden;
  background-color: var(--secondary);
  z-index: 3;
  padding: 0 var(--ml);
  height: calc(100vh - ${DOCS_HEADER_HEIGHT});
`;

interface EventsListProps {
  events: EventCollection[] | undefined;
}

const EventsList = ({ events }: EventsListProps) => {
  return (
    <Container>
      {events?.slice(0, 4)?.map((collection, i) => (
        <Collection key={i} collection={collection} />
      ))}
    </Container>
  );
};

export default EventsList;
