import { useLocation } from "@reach/router";
import React, { ReactNode } from "react";
import { Link as LinkIcon } from "react-feather";
import styled from "styled-components";

import { DOCS_HEADER_HEIGHT } from "../../Header/Header";

interface HtmrTransformProps {
  children: ReactNode;
  id: string;
}

const StyledH3 = styled.h3<{ isTarget: boolean }>`
  font-weight: var(--bold);
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: var(--m);
  // TODO: not supported on safari
  scroll-margin-top: calc(${DOCS_HEADER_HEIGHT} + 1rem);
  position: relative;

  &:hover > a {
    opacity: 1;
  }

  ${({ isTarget }) =>
    isTarget &&
    `
  {
    border: 2px solid orange;
    border-bottom: none;
    background-color: rgba(255, 165, 0, 0.2);
    margin: calc(var(--m) * -1);
    padding: var(--m);
    padding-bottom: 0;
    border-top-left-radius: var(--m);
    border-top-right-radius: var(--m);

    & + p {
      border: 2px solid orange;
      border-top: none;
      background-color: rgba(255, 165, 0, 0.2);
      margin: calc(var(--m) * -1);
      margin-bottom: var(--xl);
      padding: var(--m);
      margin-top: var(--m);
      border-bottom-left-radius: var(--m);
      border-bottom-right-radius: var(--m);
    }
  }
  `}
`;

const LinkContainer = styled.a`
  transition: opacity 50ms linear;
  opacity: 0;
  position: absolute;
  left: calc(var(--xl) * -1);
  padding: var(--m);
  padding-top: 0;

  &:hover {
    opacity: 1;
  }
`;

export const H3 = ({ children, id }: HtmrTransformProps) => {
  const { hash } = useLocation();

  return (
    <StyledH3 id={id} isTarget={hash === `#${id}`}>
      {id && (
        <LinkContainer href={`#${id}`}>
          <LinkIcon size={14} color="var(--accent)" />
        </LinkContainer>
      )}
      {children}
    </StyledH3>
  );
};

export const Paragraph = styled.p`
  margin-bottom: var(--m);
  position: relative;
  font-size: 1rem;
  line-height: 1.6em;
  word-break: break-word;

  & > strong {
    font-weight: var(--bold);
  }

  & > * > strong {
    font-weight: var(--bold);
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--borderColor);
  margin: var(--xl) 0;
  border: none;
`;

export const OrderedList = styled.ol`
  list-style-type: decimal;
  margin-bottom: var(--l);
  line-height: 1.6em;

  & > li {
    margin: var(--m) 0;
    margin-left: 1rem;
    padding-left: var(--m);

    &::marker {
      ::before {
        width: 1rem;
        font-weight: var(--xBold);
        color: var(--textColor);
        content: "• ";
      }
    }
  }
`;

export const UnOrderedList = styled.ul`
  list-style-type: disc;
  margin-bottom: var(--l);

  & > li {
    margin: var(--m) 0;
    margin-left: 1.25rem;
    padding-left: var(--m);

    &::marker {
      ::before {
        width: 1rem;
        font-weight: var(--xBold);
        color: var(--textColor);
        content: "• ";
      }
    }
  }
`;

export const BlockQuote = styled.blockquote`
  padding: var(--s) 0;
  padding-left: var(--l);
  border-left: 3px solid var(--accentOpaque);
  margin-bottom: var(--xl);
  line-height: 1.6em;
`;

export const A = styled.a`
  color: var(--accent);
  border-bottom: 1px solid var(--accent);
  line-height: 1.75em;
`;

export const U = styled.u`
  text-decoration: none;
`;
