import { VatsimEvent } from "./Event";

export const isInProgress = (d1: string, d2: string) => {
  const startDate = new Date(d1);
  const endDate = new Date(d2);
  const now = new Date(Date.now());

  return endDate > now && now > startDate;
};

export const humanReadableDay = (date: string | number) => {
  const d1 = new Date(date);
  const d2 = new Date(new Date().toDateString());
  const difference = d1.getTime() - d2.getTime();
  const daysFromNow = Math.floor(difference / (1000 * 3600 * 24));

  switch (daysFromNow) {
    case -1:
      return "Started Yesterday";
    case 0:
      return "Today";
    case 1:
      return "Tomorrow";
    default:
      return new Intl.DateTimeFormat("en-us", {
        weekday: "long",
        day: "numeric",
        month: "long",
      }).format(d1);
  }
};

const hoursMinutes = (date: string) => {
  return new Intl.DateTimeFormat("en-GB", {
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(date));
};

export const humanReadableStartEnd = (event: VatsimEvent) => {
  const start = hoursMinutes(event.startTime);
  const end = hoursMinutes(event.endTime);

  return `${start} - ${end}`;
};
