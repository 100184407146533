import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useNavigate } from "@reach/router";
import { useMapEvents } from "react-leaflet";

import { useTheme } from "../../context/Theme";
import { isSSR } from "../../hooks/helpers";
import { useLocalStorage } from "../../hooks";
import blueImg from "../../images/EVENT_MARKER.png";
import greenImg from "../../images/EVENT_MARKER_GREEN.png";

import { VatsimEvent } from "./Event";
import { EventCollection } from "./Collection";
import { isInProgress } from "./helpers";

type Leaflet = typeof import("leaflet");
type ReactLeaflet = typeof import("react-leaflet");
const L = isSSR ? undefined : (require("leaflet") as Leaflet);
const RL = isSSR ? undefined : (require("react-leaflet") as ReactLeaflet);

const Container = styled.div`
  width: 100vw;
  height: 100%;
  overflow: hidden;

  & > div {
    height: 100%;
  }

  & > div > .leaflet-pane {
    z-index: 2;

    .leaflet-bottom {
      z-index: 2;
    }
  }
`;

interface CenterProps {
  setCenter: (x: [number, number]) => void;
}

const Center = ({ setCenter }: CenterProps) => {
  const map = useMapEvents({
    dragend: () => {
      const { lat, lng } = map.getCenter();
      setCenter([lat, lng]);
    },
  });

  return null;
};

const themes = {
  dark: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
  light: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
};

interface MapProps {
  events: EventCollection[] | undefined;
}

const Map = ({ events }: MapProps) => {
  const [center, setCenter] = useLocalStorage<[number, number]>("center", [
    51.505,
    -0.09,
  ]);
  const [isDarkMode] = useTheme();
  const tileRef = useRef<L.TileLayer>(null);
  const [eventMarkers, setEventMarkers] = useState<VatsimEvent[]>();
  const navigate = useNavigate();

  useEffect(() => {
    tileRef?.current?.setUrl(isDarkMode ? themes.dark : themes.light);
  }, [isDarkMode]);

  useEffect(() => {
    if (!events) {
      return;
    }

    const upcoming = events.slice(0, 4);

    const eventsToRender = upcoming.map((event) => event.events.map((e) => e));
    setEventMarkers(eventsToRender.flat());
  }, [events]);

  const handleClick = useCallback((event: VatsimEvent) => {
    navigate(`/events/${encodeURI(event.name)}`, { state: { event } });
  }, []);

  const blueIcon = useMemo(
    () =>
      L &&
      L.icon({
        iconUrl: blueImg,
        iconSize: [25, 25],
      }),
    []
  );
  const greenIcon = useMemo(
    () =>
      L &&
      L.icon({
        iconUrl: greenImg,
        iconSize: [25, 25],
      }),
    []
  );

  if (isSSR || !RL || !L) {
    return null;
  }

  return (
    <Container>
      <RL.MapContainer
        worldCopyJump
        zoomControl={false}
        center={center}
        zoom={4}
        minZoom={4}
      >
        <Center setCenter={setCenter} />
        <RL.ZoomControl position="bottomright" />
        <RL.TileLayer
          ref={tileRef}
          // eslint-disable-next-line max-len
          attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>"
          url={isDarkMode ? themes.dark : themes.light}
        />

        {eventMarkers?.map((event, i) => (
          <div key={i}>
            {event?.airports?.map((props, j) => (
              <div key={j}>
                {props?.coords && (
                  <RL.Marker
                    eventHandlers={{ click: () => handleClick(event) }}
                    position={props.coords}
                    icon={
                      isInProgress(event.startTime, event.endTime)
                        ? greenIcon
                        : blueIcon
                    }
                  />
                )}
              </div>
            ))}
            {event.airports.length === 2 &&
              event?.airports[0]?.coords &&
              event?.airports[1]?.coords && (
                <RL.Polyline
                  eventHandlers={{ click: () => handleClick(event) }}
                  color={
                    isInProgress(event.startTime, event.endTime)
                      ? "#29B473"
                      : "#3d4fe0"
                  }
                  positions={[
                    event.airports[0].coords,
                    event.airports[1].coords,
                  ]}
                />
              )}
          </div>
        ))}
      </RL.MapContainer>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossOrigin=""
        />
      </Helmet>
    </Container>
  );
};

export default Map;
